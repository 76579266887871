<template>
    <div class="nav_title">
        <div class="name">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SubTitle"
    }
</script>

<style scoped>
    .nav_title {
        width: 100%;
        background-color: var(--subTitileBg);
        height: 32px;
        line-height: 32px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: nowrap;
        border-radius: 0px;
        border-bottom: 1px solid #454545;
    }

    .name {
        color: #ffecd0 !important;
        font-weight: bold;
        font-size: 12px;
        box-sizing: border-box;
        padding: 0 16px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .name img {
        width: 32px;
        height: 32px;
        margin-left: 15px;
    }
</style>