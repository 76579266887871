<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

<!--        <div class="content_section">-->
<!--            <div class="content">-->
<!--                <sub-title>룰렛</sub-title>-->

<!--                <div class="board_list" style="padding:20px">-->
<!--                    <div class="culcheck_box">-->

<!--                        <div class="rullet">-->
<!--                            <silver-rullet></silver-rullet>-->
<!--                        </div>-->
<!--                        <div class="rullet">-->
<!--                            <gold-rullet></gold-rullet>-->
<!--                        </div>-->
<!--                        <div class="rullet">-->
<!--                            <diamond-rullet></diamond-rullet>-->
<!--                        </div>-->

<!--                    </div>-->
<!--                    <div class="culcheck_history_box">-->
<!--                        <div>-->
<!--                            <table>-->
<!--                                <tr>-->
<!--                                    <th colspan="2">쿠폰 획득내역</th>-->
<!--                                </tr>-->
<!--                                <tr>-->
<!--                                    <th>시간</th>-->
<!--                                    <th>내역</th>-->
<!--                                </tr>-->
<!--                                <tr v-for="(item,index) in couponGetHistory">-->
<!--                                    <td>{{item.createTime|datef('YYYY-MM-DD HH:mm')}}</td>-->
<!--                                    <td>{{item.content}}</td>-->
<!--                                </tr>-->
<!--                            </table>-->
<!--                        </div>-->
<!--                        <div>-->
<!--                            <table>-->
<!--                                <tr>-->
<!--                                    <th colspan="2">쿠폰 사용내역</th>-->
<!--                                </tr>-->
<!--                                <tr>-->
<!--                                    <th>시간</th>-->
<!--                                    <th>내역</th>-->
<!--                                </tr>-->
<!--                                <tr v-for="(item,index) in couponUseHistory">-->
<!--                                    <td>{{item.createTime|datef('YYYY-MM-DD HH:mm')}}</td>-->
<!--                                    <td>{{item.content}}</td>-->
<!--                                </tr>-->
<!--                            </table>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->


<!--            </div>-->
<!--        </div>-->

        <!-- right 메뉴 -->
        <right-bar-comp>
            <right-bar-banner-comp slot="rightBanner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>
    </div>

</template>

<script>
    import {LuckyWheel, LuckyGrid} from 'vue-luck-draw'
    import TopbarComp from "../../components/TopbarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import RightBarComp from "../../components/RightBarComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import FootComp from "../../components/FootComp";
    import sportsConst from "../../common/sportsConst";
    import SubTitle from "../../components/SubTitle";
    import SilverRullet from "../../components/rullet/SilverRullet";
    import GoldRullet from "../../components/rullet/GoldRullet";
    import DiamondRullet from "../../components/rullet/DiamondRullet";
    import {getCouponHistory} from "../../network/leisureRequest";
    import {postionMixin} from "../../common/mixin";

    export default {
        name: "Rullet",
        mixins:[postionMixin],
        components: {
            DiamondRullet,
            GoldRullet,
            SilverRullet,
            SubTitle, FootComp, RightBarBannerComp, RightBarComp, SportsLeftBarComp, LeftBarComp, TopbarComp
        },
        data() {
            return {
                sportsConst,
                couponGetHistory:[],
                couponUseHistory:[],
                position:"쿠폰",
            }
        },
        methods: {

        },
        created() {
            this.$router.push('/main')
            // getCouponHistory().then(res=>{
            //     if(res.data.success){
            //         this.couponGetHistory = res.data.data['getHistory']
            //         this.couponUseHistory = res.data.data['useHistory']
            //     }
            // })
        },
    }
</script>

<style scoped>
    @import url("../../assets/css/culcheck.css");
</style>